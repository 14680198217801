import React, { useEffect, useState } from "react";
//redux
import { signupUser } from "../../redux/actions/auth";
import { connect } from "react-redux";

// components
import AuthPageContainer from "../../components/AuthPageContainer/AuthPageContainer";
import AuthCard from "../../components/AuthCard/AuthCard";
import RegistrationWelcome from "./comp/RegistrationWelcome";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import Step4 from "./steps/step4";
import Step5 from "./steps/step5";
import Step6 from "./steps/step6";
import RegistrationComplete from "./comp/RegistrationComplete";
import Summary from "./comp/Summary";
import CreatePassword from "./comp/CreatePassword";
import {
  ValidatePassword,
  ValidateRegistrationStep1,
  ValidateRegistrationStep2,
  ValidateRegistrationStep3,
  ValidateRegistrationStep4,
  ValidateRegistrationStep5,
  ValidateRegistrationStep6,
  ValidateRegistrationSummary,
  ValidateTermsAndConditions,
} from "./validators/registrationFormValidator";
import ApiService from "../../apis";
import { StyledCardBody } from "../../styles/components";
import RegistrationProgressBar from "./comp/RegSteps";
import TermsAndConditions from "./comp/TermsAndConditions";

const Signup = ({ setShowAlert, signupUser }) => {
  const query = new URLSearchParams(window.location.search);
  let redirect_url = query.get("redirect_url");
  const [availableCompanyTypes, setAvailableCompanyTypes] = useState([]);
  const [availableCountries, setAvailableCountries] = useState([]);
  const [availableRegions, setAvailableRegions] = useState([]);
  const [availableBusinessRegions, setAvailableBusinessRegions] = useState([]);
  const [availableBusinessCountries, setAvailableBusinessCountries] = useState(
    []
  );
  const [availableInterestSectors, setAvailableInterestSectors] = useState([]);
  const [availableInterestSubSectors, setAvailableInterestSubSectors] =
    useState([]);
  const [availableReasonsToJoin, setAvailableReasonsToJoin] = useState([]);
  const [step, setStep] = useState(0);
  const [signingUp, setSigningUp] = useState(false); // eslint-disable-line
  // const [signUpObj, setSignUpObj] = useState({
  //   email: "",
  //   password: "",
  //   confirmPassword: "",
  //   first_name: "",
  //   last_name: "",
  // });

  const [state, setState] = useState({
    fullName: "",
    email: "",
    companyName: "",
    companyType: "",
    region: "",
    country: "",
    businessRegions: [],
    businessCountries: [],
    interestSectors: [],
    interestSubSectors: [],
    reasonsToJoin: [],
    communicationPreference: true,
    intelligencePreference: true,
    interactionPreference: true,
    marketplacePreference: true,
    agreeTermsAndConditions: false,
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    fullNameError: "",
    emailError: "",
    companyNameError: "",
    companyTypeError: "",
    regionError: "",
    countryError: "",
    businessRegionsError: "",
    businessCountriesError: "",
    interestSectorsError: "",
    interestSubSectorsError: "",
    reasonsToJoinError: "",
    termsAndConditionsError: "",
    passwordError: "",
    confirmPasswordError: "",
  });

  const getPriorityUrl = () => {
    let data = {
      engagedev:
        "https://engagedev.ococonnect.com/wp-json/pm/v1/reactattendee_data",
      devhome:
        "https://devhomeapi.ococonnect.com/api/Registration/RegisterUser",
      home: "https://homeapi.ococonnect.com/api/Registration/RegisterUser",
    };
    let url =
      redirect_url.split("/")[2] && redirect_url.split("/")[2].split(".")[0];
    return url ? data[url] : data.devhome;
  };

  const getCompanyTypes = async () => {
    const api = new ApiService();
    await api.getCompanyTypes().then((res) => {
      setAvailableCompanyTypes(res.data);
    });
  };
  const getRegions = async () => {
    const api = new ApiService();
    await api.getGlobalRegions().then((res) => {
      setAvailableRegions(res.data);
      setAvailableBusinessRegions(res.data);
    });
  };

  const getSectors = async () => {
    const api = new ApiService();
    await api.getSectors().then((res) => {
      setAvailableInterestSectors(res.data);
    });
  };

  const getReasonsToJoin = async () => {
    const api = new ApiService();
    await api.getReasons().then((res) => {
      setAvailableReasonsToJoin(res.data);
    });
  };

  const getCountriesByRegion = async (region, multiple = false) => {
    const api = new ApiService();
    await api.getCountriesByRegionIds(region).then((res) => {
      if (!multiple) {
        setAvailableCountries(res.data);
        return;
      }
      setAvailableBusinessCountries(res.data);
    });
  };

  const incrementStep = () => {
    setStep((step) => step + 1);
  };

  const decrementStep = () => {
    setStep((step) => step - 1);
  };

  const validateStep = (e) => {
    e.preventDefault();
    setErrors({
      fullNameError: "",
      emailError: "",
      companyNameError: "",
      companyTypeError: "",
      regionError: "",
      countryError: "",
      businessRegionsError: "",
      businessCountriesError: "",
      interestSectorsError: "",
      interestSubSectorsError: "",
      reasonsToJoinError: "",
      termsAndConditionsError: "",
      passwordError: "",
      confirmPasswordError: "",
    });
    let ValidateStep;
    if (step === 1) {
      ValidateStep = ValidateRegistrationStep1({ ...state });
    } else if (step === 2) {
      ValidateStep = ValidateRegistrationStep2({ ...state });
    } else if (step === 3) {
      ValidateStep = ValidateRegistrationStep3({ ...state });
    } else if (step === 4) {
      ValidateStep = ValidateRegistrationStep4({ ...state });
    } else if (step === 5) {
      ValidateStep = ValidateRegistrationStep5({ ...state });
    } else if (step === 6) {
      ValidateStep = ValidateRegistrationStep6({ ...state });
    } else if (step === 7) {
      ValidateStep = ValidateRegistrationSummary({ ...state });
    } else if (step === 8) {
      ValidateStep = ValidateTermsAndConditions({ ...state });
    } else if (step === 9) {
      ValidateStep = ValidatePassword({ ...state });
    }
    if (ValidateStep.valid) {
      if (step === 1) {
        // check if user with email exist
        console.log("email user not ex");
        incrementStep();
      } else if (step === 9) {
        handleSignUp(e);
      } else {
        incrementStep();
      }
    } else {
      for (let i = 0, item; !!(item = ValidateStep.errors[i++]); ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  const handleChange = (name, value) => {
    // debugger;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // if (name === "region") {
    //   const regionId = [];
    //   regionId.push(value);
    //   // getCountries(regionId, true)
    //   setState((prevState) => ({ ...prevState, country: "" }));
    // }
    // if (name === "regionsOptions") {
    //   // getCountries(value, false)
    // }
  };

  console.log("state", state);

  const handleSignUp = async (e) => {
    e.preventDefault();
    // let hasError = errorHandler();
    // if (hasError) {
    //   return;
    // }
    setSigningUp(true);
    console.log(state);
    const signupResponse = await signupUser({
      ...state,
      url: getPriorityUrl(),
    });
    if (signupResponse.data?.success) {
      localStorage.setItem("token", signupResponse.data?.token);
      setSigningUp(false);
      window.location.reload();
    } else if (signupResponse.error?.email) {
      setSigningUp(false);
      setShowAlert({
        message: signupResponse.error.email[0],
        type: "error",
      });
    } else {
      setSigningUp(false);
      setShowAlert({
        message: "Something went wrong while signing up!",
        type: "error",
      });
    }
  };

  useEffect(() => {
    getCompanyTypes();
    getRegions();
    getSectors();
    getReasonsToJoin();
  }, []);
  return (
    <AuthPageContainer>
      <h1
        style={{
          fontFamily: "MICROGME",
          color: "#fff",
          fontWeight: 500,
          textAlign: "center",
          fontSize: "25px",
        }}
      >
        OCO CONNECT
      </h1>
      <RegistrationProgressBar step={step} />

      <AuthCard isSummary={step === 7}>
        <StyledCardBody isSummary={step === 7 || step === 8}>
          {/* <CardContent className={styles.cardContent}> */}
          {step === 0 && <RegistrationWelcome incrementStep={incrementStep} />}
          {step === 1 && (
            <Step1
              state={state}
              errors={errors}
              validateStep={validateStep}
              handleChange={handleChange}
              decrementStep={decrementStep}
            />
          )}
          {step === 2 && (
            <Step2
              state={state}
              errors={errors}
              validateStep={validateStep}
              handleChange={handleChange}
              decrementStep={decrementStep}
              companyTypes={availableCompanyTypes}
            />
          )}
          {step === 3 && (
            <Step3
              state={state}
              errors={errors}
              validateStep={validateStep}
              handleChange={handleChange}
              decrementStep={decrementStep}
              availableCountries={availableCountries}
              availableRegions={availableRegions}
              onRegionChange={(ids) => {
                getCountriesByRegion(ids);
              }}
            />
          )}
          {step === 4 && (
            <Step4
              state={state}
              errors={errors}
              validateStep={validateStep}
              handleChange={handleChange}
              decrementStep={decrementStep}
              availableBusinessCountries={availableBusinessCountries}
              availableBusinessRegions={availableBusinessRegions}
              onRegionChange={(ids) => {
                getCountriesByRegion(ids, true);
              }}
            />
          )}
          {step === 5 && (
            <Step5
              state={state}
              errors={errors}
              validateStep={validateStep}
              handleChange={handleChange}
              decrementStep={decrementStep}
              availableInterestSectors={availableInterestSectors}
              availableInterestSubSectors={availableInterestSubSectors}
              handleOnSectorChange={(sectors) => {
                setAvailableInterestSubSectors([
                  ...availableInterestSubSectors,
                  ...sectors,
                ]);
              }}
              setAvailableInterestSubSectors={setAvailableInterestSubSectors}
              setState={setState}
            />
          )}
          {step === 6 && (
            <Step6
              state={state}
              errors={errors}
              validateStep={validateStep}
              handleChange={handleChange}
              decrementStep={decrementStep}
              availableReasonsToJoin={availableReasonsToJoin}
            />
          )}
          {step === 7 && (
            <Summary
              state={state}
              setState={setState}
              errors={errors}
              validateStep={validateStep}
              handleChange={handleChange}
              decrementStep={decrementStep}
              availableCountries={availableCountries}
              availableRegions={availableRegions}
              availableCompanyTypes={availableCompanyTypes}
              availableInterestSectors={availableInterestSectors}
              availableInterestSubSectors={availableInterestSubSectors}
              availableBusinessCountries={availableBusinessCountries}
              availableBusinessRegions={availableBusinessRegions}
              availableReasonsToJoin={availableReasonsToJoin}
              onRegionChange={(ids, bool = false) => {
                getCountriesByRegion(ids, bool);
              }}
              handleOnSectorChange={(sectors) => {
                setAvailableInterestSubSectors([
                  ...availableInterestSubSectors,
                  ...sectors,
                ]);
              }}
              setAvailableInterestSubSectors={setAvailableInterestSubSectors}
            />
          )}
          {step === 8 && (
            <TermsAndConditions
              state={state}
              setState={setState}
              errors={errors}
              validateStep={validateStep}
              handleChange={handleChange}
              decrementStep={decrementStep}
            />
          )}
          {step === 9 && (
            <CreatePassword
              state={state}
              errors={errors}
              validateStep={validateStep}
              handleChange={handleChange}
              decrementStep={decrementStep}
            />
          )}
          {step === 10 && <CreatePassword />}
          {step === 11 && <RegistrationComplete />}
        </StyledCardBody>
        {/* </CardContent> */}
      </AuthCard>
    </AuthPageContainer>
  );
};

const mapDispatchToProps = {
  signupUser: (data) => signupUser(data),
};

export default connect(null, mapDispatchToProps)(Signup);

// import { CardActions, CardContent, CircularProgress } from "@material-ui/core";
// import React, { useState } from "react";
// //redux
// import { signupUser } from "../../redux/actions/auth";
// import { connect } from "react-redux";

// // components
// import AuthPageContainer from "../../components/AuthPageContainer/AuthPageContainer";
// import AuthCard from "../../components/AuthCard/AuthCard";
// import styles from "./styles.module.css";
// // import RegistrationWelcome from "./comp/RegistrationWelcome";
// // import Step1 from "./steps/step1";
// // import Step2 from "./steps/step2";
// // import Step3 from "./steps/step3";
// // import Step4 from "./steps/step4";
// // import Step5 from "./steps/step5";
// // import Step6 from "./steps/step6";
// // import TermsAndConditions from "./comp/TermsAndConditions";
// // import RegistrationComplete from "./comp/RegistrationComplete";
// // import Summary from "./comp/Summary";
// // import CreatePassword from "./comp/CreatePassword";
// // import {
// //   ValidatePassword,
// //   ValidateRegistrationStep1,
// //   ValidateRegistrationStep2,
// //   ValidateRegistrationStep3,
// //   ValidateRegistrationStep4,
// //   ValidateRegistrationStep5,
// //   ValidateRegistrationStep6,
// //   ValidateRegistrationSummary,
// //   ValidateTermsAndConditions,
// // } from "./validators/registrationFormValidator";
// import { ProfileIcon } from "../../components/ProfileIcon/ProfileIcon";
// import DynamicInput from "../../components/DynamicInput/DynamicInput";
// import { isEmail } from "../../utils/validators";
// import DynamicButton from "../../components/DynamicButton/DynamicButton";

// // const companyTypeOps = [
// //   {
// //     id: "6ac666cc-91b8-458b-b74a-dac400bf1224",
// //     name: "Chamber of Commerce",
// //     value: "CHAMBER_OF_COMMERCE",
// //   },
// //   {
// //     id: "68c8e5d5-c300-4ac0-ab3f-a38dc9629f36",
// //     name: "Company",
// //     value: "COMPANY",
// //   },
// //   {
// //     id: "df930976-f57d-4971-ae13-88020fd29e5f",
// //     name: "Economic Development Organisation",
// //     value: "ECONOMIC_DEVELOPMENT_ORGANISATION",
// //   },
// //   {
// //     id: "071db938-ab8f-437d-bb11-feda829e55c7",
// //     name: "Industry Association",
// //     value: "INDUSTRY_ASSOCIATION",
// //   },
// //   {
// //     id: "3ef2c217-5080-4575-912b-0003730901a6",
// //     name: "Investment Promotion Agency",
// //     value: "INVESTMENT_PROMOTION_AGENCY",
// //   },
// //   {
// //     id: "3c3ccf4f-ea91-4b6d-bf8c-0f7d186ea132",
// //     name: "Investor",
// //     value: "INVESTOR",
// //   },
// //   {
// //     id: "a8a0763e-1019-4874-9d81-7c5d35d6ff48",
// //     name: "Other",
// //     value: "OTHER",
// //   },
// // ];

// const Signup = ({ setShowAlert, signupUser }) => {
//   // // const query = new URLSearchParams(window.location.search);
//   // // let redirect_url = query.get("redirect_url");
//   // const [step, setStep] = useState(0);
//   // const [signingUp, setSigningUp] = useState(false); // eslint-disable-line
//   // // const [signUpObj, setSignUpObj] = useState({
//   // //   email: "",
//   // //   password: "",
//   // //   confirmPassword: "",
//   // //   first_name: "",
//   // //   last_name: "",
//   // // });

//   // const [state, setState] = useState({
//   //   fullName: "",
//   //   email: "",
//   //   companyName: "",
//   //   companyType: "",
//   //   region: "",
//   //   country: "",
//   //   regionsOptions: undefined,
//   //   countriesOptions: undefined,
//   //   interestSectors: undefined,
//   //   reasonsToJoin: undefined,
//   //   communicationPreference: false,
//   //   intelligencePreference: false,
//   //   interactionPreference: false,
//   //   marketplacePreference: false,
//   //   agreeTermsAndConditions: false,
//   //   password: "",
//   //   confirmPassword: "",
//   // });

//   // const [errors, setErrors] = useState({
//   //   fullNameError: "",
//   //   emailError: "",
//   //   companyNameError: "",
//   //   companyTypeError: "",
//   //   regionError: "",
//   //   countryError: "",
//   //   regionsOptionsError: "",
//   //   countriesOptionsError: "",
//   //   interestSectorsError: "",
//   //   reasonsToJoinError: "",
//   //   termsAndConditionsError: "",
//   //   passwordError: "",
//   //   confirmPasswordError: "",
//   // });

//   // // const handleChange = (name) => (event) => {
//   // //   setSignUpObj({ ...signUpObj, [name]: event.target.value });
//   // // };

//   // // const errorHandler = () => {
//   // //   let email_error = "";
//   // //   let password_error = "";
//   // //   let confirmPassword_error = "";
//   // //   let first_name_error = "";
//   // //   let last_name_error = "";
//   // //   let error = false;
//   // //   if (signUpObj.email === "") {
//   // //     email_error = "Email is required";
//   // //     error = true;
//   // //   }
//   // //   if (signUpObj.email && !isEmail(signUpObj.email)) {
//   // //     email_error = "Enter a valid email address";
//   // //     error = true;
//   // //   }
//   // //   if (signUpObj.password === "") {
//   // //     password_error = "Password is required";
//   // //     error = true;
//   // //   }
//   // //   if (signUpObj.confirmPassword === "") {
//   // //     confirmPassword_error = "Confirm Password is required";
//   // //     error = true;
//   // //   }
//   // //   if (signUpObj.first_name === "") {
//   // //     first_name_error = "First Name is required";
//   // //     error = true;
//   // //   }
//   // //   if (signUpObj.last_name === "") {
//   // //     last_name_error = "Last Name is required";
//   // //     error = true;
//   // //   }
//   // //   if (signUpObj.password !== signUpObj.confirmPassword) {
//   // //     confirmPassword_error = "Password and Confirm Password must match";
//   // //     error = true;
//   // //   }
//   // //   setErrorObj({
//   // //     email_error,
//   // //     password_error,
//   // //     confirmPassword_error,
//   // //     first_name_error,
//   // //     last_name_error,
//   // //   });
//   // //   return error;
//   // // };

//   // const incrementStep = () => {
//   //   setStep((step) => step + 1);
//   // };

//   // const decrementStep = () => {
//   //   setStep((step) => step - 1);
//   // };

//   // const validateStep = (e) => {
//   //   e.preventDefault();
//   //   setErrors({
//   //     fullNameError: "",
//   //     emailError: "",
//   //     companyNameError: "",
//   //     companyTypeError: "",
//   //     regionError: "",
//   //     countryError: "",
//   //     regionsOptionsError: "",
//   //     countriesOptionsError: "",
//   //     interestSectorsError: "",
//   //     reasonsToJoinError: "",
//   //     termsAndConditionsError: "",
//   //     passwordError: "",
//   //     confirmPasswordError: "",
//   //   });
//   //   let ValidateStep;
//   //   if (step === 1) {
//   //     ValidateStep = ValidateRegistrationStep1({ ...state });
//   //   } else if (step === 2) {
//   //     ValidateStep = ValidateRegistrationStep2({ ...state });
//   //   } else if (step === 3) {
//   //     ValidateStep = ValidateRegistrationStep3({ ...state });
//   //   } else if (step === 4) {
//   //     ValidateStep = ValidateRegistrationStep4({ ...state });
//   //   } else if (step === 5) {
//   //     ValidateStep = ValidateRegistrationStep5({ ...state });
//   //   } else if (step === 6) {
//   //     ValidateStep = ValidateRegistrationStep6({ ...state });
//   //   } else if (step === 7) {
//   //     ValidateStep = ValidateRegistrationSummary({ ...state });
//   //   } else if (step === 8) {
//   //     ValidateStep = ValidateTermsAndConditions({ ...state });
//   //   } else if (step === 9) {
//   //     ValidateStep = ValidatePassword({ ...state });
//   //   }
//   //   if (ValidateStep.valid) {
//   //     if (step === 1) {
//   //       // check if user with email exist
//   //       console.log("email user not ex");
//   //       incrementStep();
//   //     } else if (step === 9) {
//   //       handleSignUp(e);
//   //     } else {
//   //       incrementStep();
//   //     }
//   //   } else {
//   //     for (let i = 0, item; !!(item = ValidateStep.errors[i++]); ) {
//   //       setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
//   //     }
//   //   }
//   // };

//   // const handleChange = (name, value) => {
//   //   // debugger;

//   //   setState((prevState) => ({
//   //     ...prevState,
//   //     [name]: value,
//   //   }));
//   //   // if (name === "region") {
//   //   //   const regionId = [];
//   //   //   regionId.push(value);
//   //   //   // getCountries(regionId, true)
//   //   //   setState((prevState) => ({ ...prevState, country: "" }));
//   //   // }
//   //   // if (name === "regionsOptions") {
//   //   //   // getCountries(value, false)
//   //   // }
//   // };

//   // const handleSignUp = async (e) => {
//   //   e.preventDefault();
//   //   // let hasError = errorHandler();
//   //   // if (hasError) {
//   //   //   return;
//   //   // }
//   //   setSigningUp(true);
//   //   console.log(state);
//   //   // const signupResponse = await signupUser({
//   //   // email: signUpObj.email,
//   //   // password: signUpObj.password,
//   //   // first_name: signUpObj.first_name,
//   //   // last_name: signUpObj.last_name,
//   //   // url: redirect_url || "",
//   //   // });
//   //   // if (signupResponse.data?.success) {
//   //   //   localStorage.setItem("token", signupResponse.data?.token);
//   //   //   setSigningUp(false);
//   //   //   window.location.reload();
//   //   // } else if (signupResponse.error?.email) {
//   //   //   setSigningUp(false);
//   //   //   setShowAlert({
//   //   //     message: signupResponse.error.email[0],
//   //   //     type: "error",
//   //   //   });
//   //   // } else {
//   //   //   setSigningUp(false);
//   //   //   setShowAlert({
//   //   //     message: "Something went wrong while signing up!",
//   //   //     type: "error",
//   //   //   });
//   //   // }
//   // };
//   // return (
//   //   <AuthPageContainer>
//   //     <AuthCard>
//   //       <CardContent className={styles.cardContent}>
//   //         {step === 0 && <RegistrationWelcome incrementStep={incrementStep} />}
//   //         {step === 1 && (
//   //           <Step1
//   //             state={state}
//   //             errors={errors}
//   //             validateStep={validateStep}
//   //             handleChange={handleChange}
//   //             decrementStep={decrementStep}
//   //           />
//   //         )}
//   //         {step === 2 && (
//   //           <Step2
//   //             state={state}
//   //             errors={errors}
//   //             validateStep={validateStep}
//   //             handleChange={handleChange}
//   //             decrementStep={decrementStep}
//   //             companyTypes={companyTypeOps}
//   //           />
//   //         )}
//   //         {step === 3 && <Step3 />}
//   //         {step === 4 && <Step4 />}
//   //         {step === 5 && <Step5 />}
//   //         {step === 6 && <Step6 />}
//   //         {step === 7 && <Summary />}
//   //         {step === 8 && <TermsAndConditions />}
//   //         {step === 9 && <CreatePassword />}
//   //         {step === 10 && <RegistrationComplete />}
//   //       </CardContent>
//   //     </AuthCard>
//   //   </AuthPageContainer>
//   // );
//   const query = new URLSearchParams(window.location.search);
//   let redirect_url = query.get("redirect_url");
//   const [signingUp, setSigningUp] = useState(false);
//   const [signUpObj, setSignUpObj] = useState({
//     email: "",
//     password: "",
//     confirmPassword: "",
//     first_name: "",
//     last_name: "",
//   });

//   const [errorObj, setErrorObj] = useState({
//     email_error: "",
//     password_error: "",
//     confirmPassword_error: "",
//     first_name_error: "",
//     last_name_error: "",
//   });

//   const handleChange = (name) => (event) => {
//     setSignUpObj({ ...signUpObj, [name]: event.target.value });
//   };

//   const errorHandler = () => {
//     let email_error = "";
//     let password_error = "";
//     let confirmPassword_error = "";
//     let first_name_error = "";
//     let last_name_error = "";
//     let error = false;
//     if (signUpObj.email === "") {
//       email_error = "Email is required";
//       error = true;
//     }
//     if (signUpObj.email && !isEmail(signUpObj.email)) {
//       email_error = "Enter a valid email address";
//       error = true;
//     }
//     if (signUpObj.password === "") {
//       password_error = "Password is required";
//       error = true;
//     }
//     if (signUpObj.confirmPassword === "") {
//       confirmPassword_error = "Confirm Password is required";
//       error = true;
//     }
//     if (signUpObj.first_name === "") {
//       first_name_error = "First Name is required";
//       error = true;
//     }
//     if (signUpObj.last_name === "") {
//       last_name_error = "Last Name is required";
//       error = true;
//     }
//     if (signUpObj.password !== signUpObj.confirmPassword) {
//       confirmPassword_error = "Password and Confirm Password must match";
//       error = true;
//     }
//     setErrorObj({
//       email_error,
//       password_error,
//       confirmPassword_error,
//       first_name_error,
//       last_name_error,
//     });
//     return error;
//   };

//   const handleSignUp = async (e) => {
//     e.preventDefault();
//     let hasError = errorHandler();
//     if (hasError) {
//       return;
//     }
//     setSigningUp(true);

//     const signupResponse = await signupUser({
//       email: signUpObj.email,
//       password: signUpObj.password,
//       first_name: signUpObj.first_name,
//       last_name: signUpObj.last_name,
//       url: redirect_url || "",
//     });
//     if (signupResponse.data?.success) {
//       localStorage.setItem("token", signupResponse.data?.token);
//       setSigningUp(false);
//       console.log(signUpObj);
//       window.location.reload();
//     } else if (signupResponse.error?.email) {
//       setSigningUp(false);
//       setShowAlert({
//         message: signupResponse.error.email[0],
//         type: "error",
//       });
//     } else {
//       setSigningUp(false);
//       setShowAlert({
//         message: "Something went wrong while signing up!",
//         type: "error",
//       });
//     }
//   };
//   return (
//     <AuthPageContainer>
//       <AuthCard>
//         <CardContent className={styles.cardContent}>
//           <ProfileIcon
//             style={{
//               marginTop: "18px",
//             }}
//             height="70px"
//             width="70px"
//           />
//           <h4
//             style={{
//               color: "white",
//               paddingTop: "16px",
//               fontSize: "22px",
//               margin: 0,
//               textAlign: "center",
//             }}
//           >
//             Signup
//           </h4>
//           <CardContent
//             style={{
//               display: "flex",
//               flexDirection: "column",
//             }}
//           >
//             <div style={{ margin: "5px 0" }}>
//               <DynamicInput
//                 variant="outlined"
//                 placeholder="First name"
//                 type="text"
//                 style={{ width: "100%" }}
//                 error={errorObj.first_name_error}
//                 value={signUpObj.first_name}
//                 onChange={handleChange("first_name")}
//                 onFocus={() =>
//                   setErrorObj({ ...errorObj, first_name_error: "" })
//                 }
//               />
//             </div>
//             <div style={{ margin: "5px 0" }}>
//               <DynamicInput
//                 variant="outlined"
//                 placeholder="Last name"
//                 type="text"
//                 style={{ width: "100%" }}
//                 error={errorObj.last_name_error}
//                 value={signUpObj.last_name}
//                 onChange={handleChange("last_name")}
//                 onFocus={() =>
//                   setErrorObj({ ...errorObj, last_name_error: "" })
//                 }
//               />
//             </div>
//             <div style={{ margin: "5px 0" }}>
//               <DynamicInput
//                 variant="outlined"
//                 placeholder="Email"
//                 type="email"
//                 style={{ width: "100%" }}
//                 error={errorObj.email_error}
//                 value={signUpObj.email}
//                 onChange={handleChange("email")}
//                 onFocus={() => setErrorObj({ ...errorObj, email_error: "" })}
//               />
//             </div>
//             <div style={{ margin: "5px 0" }}>
//               <DynamicInput
//                 variant="outlined"
//                 placeholder="Password"
//                 type="password"
//                 style={{ width: "100%" }}
//                 error={errorObj.password_error}
//                 value={signUpObj.password}
//                 onChange={handleChange("password")}
//                 onFocus={() => setErrorObj({ ...errorObj, password_error: "" })}
//               />
//             </div>
//             <div style={{ margin: "5px 0" }}>
//               <DynamicInput
//                 variant="outlined"
//                 placeholder="Confirm password"
//                 type="password"
//                 style={{ width: "100%" }}
//                 error={errorObj.confirmPassword_error}
//                 value={signUpObj.confirmPassword}
//                 onChange={handleChange("confirmPassword")}
//                 onFocus={() =>
//                   setErrorObj({ ...errorObj, confirmPassword_error: "" })
//                 }
//               />
//             </div>
//           </CardContent>
//           <CardActions
//             style={{
//               padding: "0 16px 16px",
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "flex-end",
//             }}
//           >
//             {signingUp ? (
//               <DynamicButton
//                 style={{
//                   width: "100%",
//                   backgroundColor: "#3f51b5",
//                   color: "white",
//                 }}
//               >
//                 <CircularProgress
//                   color="#ffffff"
//                   style={{ width: "24px", height: "24px" }}
//                 />
//               </DynamicButton>
//             ) : (
//               <DynamicButton
//                 style={{
//                   width: "100%",
//                   backgroundColor: "#3f51b5",
//                   color: "white",
//                 }}
//                 onClick={handleSignUp}
//               >
//                 Signup
//               </DynamicButton>
//             )}

//             <small
//               style={{
//                 position: "relative",
//                 color: "#fff",
//                 marginTop: "25px",
//                 fontWeight: "600",
//                 fontSize: "15px",
//               }}
//             >
//               Already have an account?{" "}
//               <a
//                 href="/signin"
//                 style={{
//                   color: "#fff",
//                 }}
//               >
//                 Log in here
//               </a>
//             </small>
//           </CardActions>
//         </CardContent>
//       </AuthCard>
//     </AuthPageContainer>
//   );
// };

// const mapDispatchToProps = {
//   signupUser: (data) => signupUser(data),
// };

// export default connect(null, mapDispatchToProps)(Signup);
